jQuery(document).foundation();
/*
 These functions make sure WordPress
 and Foundation play nice together.
 */

jQuery(document).ready(function () {

    // Remove empty P tags created by WP inside of Accordion and Orbit
    jQuery('.accordion p:empty, .orbit p:empty').remove();

    // Makes sure last grid item floats left
    jQuery('.archive-grid .columns').last().addClass('end');

    // Adds Flex Video to YouTube and Vimeo Embeds
    jQuery('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').each(function () {
        if (jQuery(this).innerWidth() / jQuery(this).innerHeight() > 1.5) {
            jQuery(this).wrap("<div class='widescreen flex-video'/>");
        } else {
            jQuery(this).wrap("<div class='flex-video'/>");
        }
    });


    $('.home3__slider-js').slick({
        slidesToShow: 5,
        slidesToScroll: 5,
        infinite: false,
            responsive: [
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
infinite: false,
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: false,
      }
    },
        {
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
      }
    }
  ]
    });

    $('.header-slider-js').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    });

    $('.thumb-slider-js').slick({
        infinite: false,
        slidesToShow: 9,
        slidesToScroll: 9,
        responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 6,
        infinite: false,
      }
    },
    {
      breakpoint: 850,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
infinite: false,
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
      }
    },
        {
      breakpoint: 420,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
      }
    }
  ]
    });

});
